<template>
  <module
      ref="module"
      id="addStrategy"
      titleIcon="fa fa-create"
      :title="$t('createstrategy')"
      :use-default-list="false"
    >
    <div slot="global">
      <div v-if="!cancreatestrat">
        <div class="col-md-12">
          <div class="alert alert-danger">
            <i class="fa fa-warning"></i>
            {{ $t("notallowedtocreatestrategies") }}
          </div>
        </div>
      </div>
      <div v-if="cancreatestrat" class="row d-flex justify-content-center">
        <div class="col-md-10 mr-auto ml-auto">

            <div class="row text-center"  v-if="alert_me">
              <div class="col-12">
                <div :class="alert_class" class="ml-2 mb-2" style="display: block;font-weight: bold;">
                  {{ alert_msg }}
                </div>
              </div>
            </div>

            <div v-if="inprogress" class="text-center">
              <h2>{{ inprogresstxt }}</h2>
              <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
            </div>

            <wizard :prevButtonText="prevBtnTxt" :nextButtonText="nextBtnTxt" :finishButtonText="finBtnTxt" v-else>
              <template slot="header">
                <h3 class="card-title">{{ $t('createaccount') }}</h3>
                <h3 class="description">{{ $t('whystrategy') }}</h3>
              </template>

              <wizard-tab :before-change="() => validateStep('intro')">
                <template slot="label">
                  <i class="fa fa-th-large"></i>
                  {{ $t('accountstxt') }}
                </template>
                <get-master-step ref="intro" @on-validated="onStepValidated"></get-master-step>
              </wizard-tab>

              <wizard-tab :before-change="() => validateStep('terms')">
                <template slot="label">
                  <i class="fa fa-flag-checkered"></i>
                  {{ $t('finish') }}
                </template>
                <terms ref="terms" @on-validated="onStepValidated"></terms>
              </wizard-tab>

            </wizard>
        </div>
      </div>
    </div>
  </module>
</template>
<script>
  import GetMasterStep from './AddStrategyWiz/GetMasterAccount.vue'
  import Terms from './AddStrategyWiz/Terms.vue'
  import Constants from 'src/assets/constants'
  import {Wizard, WizardTab} from 'src/components/UIComponents'
  import swal from 'sweetalert2'
  const user_data = JSON.parse(localStorage.getItem('user-info'));

  export default {
    data() {
      return {
        wizardModel: {},
        prevBtnTxt: this.$t('previous'),
        nextBtnTxt: this.$t('next'),
        finBtnTxt: this.$t('create'),
        inprogress: false,
        inprogresstxt: this.$t('creatingstrategy'),
        alert_me: false,
        alert_msg: null,
        alert_class: 'text-danger',
        isadmin: false,
        walletapp: Constants.methods.walletme(),
        userid: null,
        cancreatestrat: true
      }
    },
    components: {
      GetMasterStep,
      Terms,
      Wizard,
      WizardTab,
    },
    methods: {
      addDone(resp) {
        if(!resp.success) {
          return this.failop({data: resp});
        }
        if (this.isadmin && this.wizardModel.sachange) {
          this.$updateAsset(this.wizardModel.update, this.wizardModel.sa_tradedAssets).then(this.doneAll, this.failop);
        } else {
          localStorage.removeItem('hc_accounts-'+ this.userid);
          localStorage.removeItem('hc_admin_all_strats-'+ this.userid);
          this.inprogress = false;
          if (this.wizardModel.public) {
            swal(this.$t("successtxt"), this.$t('public_strategy_created') , "success").then(this.changeLocation)
          } else {
            swal(this.$t("successtxt"),this.$t('private_strategy_created') , "success").then(this.changeLocation)
          }
        }
      },
      changeLocation(){
        if (this.isadmin) {
          location.href = "/admin_accounts/"+ this.userid;
        }
        else {
          location.href = "/accounts/main";
        }
      },
      doneAll(resp) {
        localStorage.removeItem('hc_accounts-'+ this.userid);
        localStorage.removeItem('hc_admin_all_strats-'+ this.userid);
        this.inprogress = false;
        location.href = "/admin_accounts/"+ this.userid;
      },
      failop(error) {
        this.inprogress = false;
        this.alert_me = true;
        if (error.data.msg !== undefined && error.data.msg !== null) {
          this.alert_msg = this.$t(error.data.msg);
        } else {
          this.alert_msg = this.$t('failcreatingstrat');
        }
      },
      getObject() {
        return this.wizardModel;
      },
      validateStep(ref) {
        return this.$refs[ref].validate();
      },
      onStepValidated(validated, model) {
        this.wizardModel = {...this.wizardModel, ...model};
        let proceed = true;

        if (model.notagreed !== undefined) {
          if (model.notagreed) {
            this.$refs['terms'].reload(this.wizardModel);
          }
        }

        if (this.wizardModel.agree !== undefined) {
          if (this.wizardModel.agree) {
            this.inprogress = true;
            const uid = this.wizardModel.uid;
            // Compose the JSON
            let ujson = {
                "strat_name": this.wizardModel.name,
                "master_id": this.wizardModel.account_id,
                "discoverable": this.wizardModel.public,
                "price": this.wizardModel.public_price,
                "strat_desc": this.wizardModel.public_desc,
                "shared": false,
                "minimum_balance": this.wizardModel.minimum_balance
            };
            if (!this.wizardModel.public) {
              ujson["price"] = 0;
              delete ujson.strat_desc;
            } else {
              ujson["price"] = parseFloat(ujson["price"]) / 100;
            }
            let sid = null;
            if (this.wizardModel.update) {
              sid = this.wizardModel.update;
            }
            // Send to API
            this.$upsertStrategy(null, ujson, sid).then(this.addDone, this.failop);
          }
        }

      },
      callBackCheckSettings(resp) {
        this.cancreatestrat = !!resp.msg.create_strategies || this.adminusr || this.isbroker
      }
    },
    mounted() {
      if (user_data.roles.indexOf('admin') >= 0) {
        this.isadmin = true;
      }
      this.userid = user_data.email;
      if (this.$route.params.userid) {
        this.userid = this.$route.params.userid;
      }
      if (this.$route.params.sid && this.$route.params.sid !== 'add') {
        this.finBtnTxt = this.$t('upentrybtn');
      }
      if (Constants.methods.getRules().isWhitelabelSession) {
        this.$getWhitelabelSettings()
          .then(this.callBackWhitelabelSettings, this.handler)
      }
    },
    created(){
      this.$setTranslatingInProgress()
    },
    beforeDestroy(){
      this.$root.$off("showTranslatingProgress")
    },
    handler() {
    }
  }
</script>
<style lang="scss">
  .adjust-width {
    width: 225px;
  }
  .info-icon {
    position: relative;
    cursor: auto;
    z-index: 999;
  }
  .info-l0 {
    left: 0;
  }
  .info-l5 {
    left: 5px;
  }
  .info-l10 {
    left: -10px;
  }
  .info-t2 {
    top: 2px;
  }
  .info-t5 {
    top: 5px;
  }
  .info-t16 {
    top: 16px;
  }
  .info-t13 {
    top: 13px;
  }
  .info-t20 {
    top: 20px;
  }
  .info-t40 {
    top: 40px;
  }
  .label-check label {
    color: #000;
    font-size: 13px;
  }
  .row {
    padding-bottom: 10px;
  }
  .sub-text {
    position: relative;
    left: 15px;
  }
</style>
